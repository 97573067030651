import React from 'react';
import { 
	ImageFormatter, 
	useTranslation 
} from '../../index';

const location = require('../../../images/services/yobale-taxi-a-la-journee.png')
const locationWebp = require('../../../images/services/yobale-taxi-a-la-journee.webp')
const aeroport = require('../../../images/services/aibd-dakar-airport.png')
const aeroportWebp = require('../../../images/services/aibd-dakar-airport.webp')
const urbains = require('../../../images/services/yobale-transferts-interurbains.png')
const urbainsWebp = require('../../../images/services/yobale-transferts-interurbains.webp')

const ArgumentsServices = () => {
	const servicesArgumentsSection = useTranslation("servicesArgumentsSection");
	return (
		<div className="Arguments">
			<div className="ServicesArgumentsContainer">

				<div className="ServicesColumn">
					<ImageFormatter 
						className="ServicesColumnLogo" 
						webpImage={aeroportWebp} 
						image={aeroport} 
						alt="VTC-confort-sénégal" 
						width={"400"}
						height={"250"}
					/>
					<div className="ServicesSubContainer">
						<h2 className="ArgumentsColumnTitle">{servicesArgumentsSection.title1}</h2>
						<p className="ServicesParagraphLeft">{servicesArgumentsSection.arg11}</p>
						<p className="ServicesParagraphLeft">{servicesArgumentsSection.arg12}</p>
						<p className="ServicesParagraphLeft">{servicesArgumentsSection.arg13}</p>
					</div>
				</div>

				<div className="ServicesColumn">
					<ImageFormatter 
						className="ServicesColumnLogo" 
						webpImage={urbainsWebp} 
						image={urbains} 
						alt="VTC-écologiques-sénégal" 
						width={"400"}
						height={"250"}
					/>
					<div className="ServicesSubContainer">
						<h2 className="ArgumentsColumnTitle">{servicesArgumentsSection.title2}</h2>
						<p className="ServicesParagraphLeft">{servicesArgumentsSection.arg21}</p>
					</div>
				</div>

				<div className="ServicesColumn">
					<ImageFormatter 
						className="ServicesColumnLogo" 
						webpImage={locationWebp} 
						image={location} 
						alt="réserver-VTC-sénégal-transfert-aéroport-DSS" 
						width={"400"}
						height={"250"}
					/>
					<div className="ServicesSubContainer">
						<h2 className="ArgumentsColumnTitle">{servicesArgumentsSection.title3}</h2>
						<p className="ServicesParagraphLeft">{servicesArgumentsSection.arg31}</p>
						<p className="ServicesParagraphLeft">{servicesArgumentsSection.arg32}</p>
					</div>
				</div>

			</div>
		</div>			
	)
}
export default ArgumentsServices;