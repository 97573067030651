import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';

const Select = ({
  value,
  onValueChange,
  possibleValues,
  displayedValues,
  ariaLabel,
  fixedTitle,
  customTriggerClass,
  customSelectClass,
  langFlag,
}) => {
  return (
    <SelectPrimitive.Root value={value} onValueChange={onValueChange}>
      <SelectPrimitive.Trigger
        asChild
        aria-label={ariaLabel}
        className={customTriggerClass ? customTriggerClass : 'SelectTrigger'}
      >
        <div>
          {langFlag && langFlag(value)}
          {fixedTitle && fixedTitle.length > 0 ? (
            fixedTitle
          ) : (
            <SelectPrimitive.Value aria-label={value} />
          )}
        </div>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Content
        className="SelectContent"
        position="popper"
        sideOffset={5}
      >
        <SelectPrimitive.Viewport>
          <SelectPrimitive.Group>
            {possibleValues?.map((v, i) => (
              <SelectPrimitive.Item
                key={`${v}-${i}`}
                value={v}
                className={customSelectClass ? customSelectClass : 'SelectItem'}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  {langFlag && langFlag(v)}
                  <SelectPrimitive.ItemText>
                    {displayedValues[i]}
                  </SelectPrimitive.ItemText>
                </div>
              </SelectPrimitive.Item>
            ))}
          </SelectPrimitive.Group>
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Root>
  );
};

export default Select;
