import { useContext } from "react";
import Rating from "react-rating";
import { DisplayContext, ImageFormatter, useTranslation } from "../../index";
import { ratingsConfig } from "../../../config/config";

import googleLogo from "../../../images/reviews/Google_logo_150.png";
import googleLogoWebp from "../../../images/reviews/Google_logo_150.webp";

const GoogleMapsReview = () => {
  const { googleTotalReviews } = useContext(DisplayContext);
  const reviewsText = useTranslation("reviewsText");
  const externalLinks = useTranslation("externalLinks")
  const rating = ratingsConfig.GOOGLE_DEFAULT_RATING;

  // IN CASE OF DYNAMIC RATING
  // const [rating, setRating] = useState(ratingsConfig.GOOGLE_DEFAULT_RATING);
  // const [totalReviews, settotalReviews] = useState(ratingsConfig.GOOGLE_DEFAULT_REVIEWS_NUMBER);
  // const isRatingDynamic = ratingsConfig.DYNAMIC_RATINGS;

  // useEffect(() => {
  //   if (!isRatingDynamic) return;
  //   async function getRate() {
  //     const google = window.google;
  //     const service = new google.maps.places.PlacesService(document.createElement('div'));

  //     const request = {
  //       placeId: googleConfig.GOOGLE_PLACE_ID,
  //       fields: ["rating","user_ratings_total","reviews"],
  //     };

  //     service.getDetails(request, (place, status) => {
  //       if (status === google.maps.places.PlacesServiceStatus.OK) {
  //         setRating(place.rating.toFixed(googleConfig.GOOGLE_RATING_DECIMALS));
  //         settotalReviews(place.user_ratings_total);
  //       } else {
  //         console.error("Error fetching place details:", status);
  //       }
  //     });
  //   }
  //   getRate();
  // }, [isRatingDynamic])

  return (
    <a 
      className="RatingContainer GoogleReviewContainer"
      target="_blank"
      rel="noreferrer"
      href={externalLinks?.google}
    >
      <ImageFormatter 
        className="GoogleReviewLogo"
        webpImage={googleLogoWebp}
        image={googleLogo}
        alt="Google Review" 
        width={"100"}
        height={"34"}
      />  
      {/* <div className="GoogleRatingValue">{rating}</div> */}
      <div className="GoogleRatingContainer">
        <Rating
          placeholderRating={rating}
          emptySymbol={<div className="grey-star"></div>}
          placeholderSymbol={<div className="clip-star"></div>}
          fullSymbol={<div className="clip-star"></div>}
          readonly
        />
        <p style={{fontWeight: "600"}}>{googleTotalReviews}{reviewsText.reviewsWord}</p>
      </div>
      <div className="GoogleReviewRead">
        {reviewsText.readOurReviews}
      </div>
    </a>
  )
}
export default GoogleMapsReview;