import React from 'react';

const ImageFormatter = ({ className, webpImage, image, alt, containerClass, width, height }) => {
  return (
    <picture className={containerClass}>
      <source srcSet={webpImage} type='image/webp' />
      <img className={className} src={image} alt={alt} width={width} height={height}/>
    </picture>
  );
};
export default ImageFormatter;