import React from 'react';
import { useTranslation } from '../../index';

const TableauTarifs = () => {
  const tableauTarifsText = useTranslation("tableauTarifs");
  return (
    <div>
      <div className="TarifsContainerRow">
        <div className="TarifsColumnItemDescription">
        <div>{tableauTarifsText.dest1}</div>
        </div>
        <div className="TarifsColumnItemPrice">
          <div className="TarifsColumnItemPriceContainer">
            <div>{tableauTarifsText.option1}&#160;</div>
            <div>27 000 FCFA</div>
          </div>
          <div>(41€)</div>
        </div>
      </div>
      <div className="TarifsContainerRow">
        <div className="TarifsColumnItemDescription">
          <div>{tableauTarifsText.dest2}</div>
        </div>
        <div className="TarifsColumnItemPrice">
          <div className="TarifsColumnItemPriceContainer">
            <div>{tableauTarifsText.option1}&#160;</div>
            <div>32 000 FCFA</div>
          </div>
          <div>(49€)</div>
        </div>
      </div>
      <div className="TarifsContainerRow">
        <div className="TarifsColumnItemDescription">
          <div>{tableauTarifsText.dest3}</div>
        </div>
        <div className="TarifsColumnItemPrice">
          <div className="TarifsColumnItemPriceContainer">
            <div>{tableauTarifsText.option1}&#160;</div>
            <div>35 000 FCFA</div>
          </div>
          <div>(53€)</div>
        </div>
      </div>
      <div className="TarifsContainerRow">
        <div className="TarifsColumnItemDescription">
          <div>{tableauTarifsText.dest4}</div>
        </div>
        <div className="TarifsColumnItemPrice">
          <div className="TarifsColumnItemPriceContainer">
            <div>{tableauTarifsText.option1}&#160;</div>
            <div>17 000 FCFA</div>
          </div>
          <div>(26€)</div>
        </div>
      </div>
      <div className="TarifsContainerRow">
        <div className="TarifsColumnItemDescription">
          <div>{tableauTarifsText.dest5}</div>
        </div>
        <div className="TarifsColumnItemPrice">
          <div className="TarifsColumnItemPriceContainer">
            <div>{tableauTarifsText.option1}&#160;</div>
            <div>45 000 FCFA</div>
          </div>
          <div>(69€)</div>
        </div>
      </div>
      <div className="TarifsContainerRow">
        <div className="TarifsColumnItemDescription">
          <div>{tableauTarifsText.dest6}</div>
        </div>
        <div className="TarifsColumnItemPrice">
          <div className="TarifsColumnItemPriceContainer">
            <div>{tableauTarifsText.option1}&#160;</div>
            <div>55 000 FCFA</div>
          </div>
          <div>(84€)</div>
        </div>
      </div>
    </div>
  )
}
export default TableauTarifs;