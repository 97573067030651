import { useContext } from "react";
import { 
  Button,
  DisplayContext, 
  useTranslation 
} from "../../index";

const BookingButton = (props) => {
  const { handleBookingFormFocusAlways } = useContext(DisplayContext);
  const buttonsText = useTranslation("buttonsText");
  const {width, fontSize} = props;
  return (
    <Button 
      action={handleBookingFormFocusAlways}
      text={buttonsText?.booking}
      width={width}
      fontSize={fontSize}
    />
  )
}
export default BookingButton;