import React, { useContext } from 'react';
import { 
	DisplayContext,
	ImageFormatter,
	Burger,
	BurgerMenu,
	useTranslation,
	LangSelect,
	BookingButton,
	Select,
	useDevice
} from '../../index';

const logo = require('../../../images/brand/yobale-transport-aeroport-logo.jpg');
const logoWebp = require('../../../images/brand/yobale-transport-aeroport-logo.webp');

const Header = () => {
	const {
		goTo,
		isBurgerMenuOpen,
		toggleBurgerMenu,
		toggleContactForm,
		closePricingPopUp,
		closeContactForm,
		handleReduceForm,
		handleBookingFormFocusAlways,
	} = useContext(DisplayContext)
	const { desktop } = useDevice();
	const headerSection = useTranslation("headerSection")

	const entrepriseValues = [headerSection?.menu31, headerSection?.menu32, headerSection?.menu33].map((v) => v.toUpperCase())
	const possibleValues = ["goToConcept", "goToVehicules", "goToTeam"]
	const handleMenuSelect = (e) => {
		goTo(e)
		closeContactForm()
		closePricingPopUp()
	}

	return (
		<header className="Header">
			<div className="SectionContained">

				<div className="Navbar">
					<div className="HeaderLeftContainer" onClick={handleReduceForm} >
						<ImageFormatter className="HeaderLogo" webpImage={logoWebp} image={logo} alt="yobale-transport-aeroport-logo" />
					</div>
					{desktop ? 
						<div className="HeaderRightContainer">
							<div className="HeaderRightButton" onClick={() => {handleMenuSelect("goToServices")}}>{headerSection?.menu1}</div>
							<div className="HeaderRightButton" onClick={() => {handleMenuSelect("goToRoutes")}}>{headerSection?.menu2}</div>
							<Select 
								customTriggerClass={"HeaderRightButton"}
								customSelectClass={"SelectItem SelectItemGreen"}
								value={headerSection?.menu3}
								onValueChange={e => handleMenuSelect(e)} 
								possibleValues={possibleValues}
								displayedValues={entrepriseValues}
								ariaLabel={"company-section"}
								fixedTitle={headerSection?.menu3}
							/>
							<div className="HeaderRightButton" onClick={() => {handleMenuSelect("goToFaq")}}>{headerSection?.menu6}</div>
							<div className="HeaderRightButton" 
								onClick={() => {
									closePricingPopUp()
									toggleContactForm()
								}}
							>{headerSection?.menu8}</div>
							<BookingButton fontSize={"16px"}/>
							<LangSelect />
						</div>
						:
						<>
							<Burger
								isBurgerMenuOpen={isBurgerMenuOpen}
								toggleBurgerMenu={toggleBurgerMenu}
								/>
							<BurgerMenu 
								isBurgerMenuOpen={isBurgerMenuOpen}
								toggleBurgerMenu={toggleBurgerMenu}
								handleBookingFormFocusAlways={handleBookingFormFocusAlways}
								toggleContactForm={toggleContactForm}
							/>
						</>
					}
				</div>
			</div>
		</header>
	)
}

export default Header