import { useContext, useEffect, useState } from 'react';
import { langConfig } from '../../config/config';
import { ImageFormatter, LangContext, Select } from '../index';

const frFlag = require('../../images/lang/fr_flag_80.png');
const frFlagWebp = require('../../images/lang/fr_flag_80.webp');
const enFlag = require('../../images/lang/us_flag_80.png');
const enFlagWebp = require('../../images/lang/us_flag_80.webp');
const esFlag = require('../../images/lang/sp_flag_80.png');
const esFlagWebp = require('../../images/lang/sp_flag_80.webp');
const brFlag = require('../../images/lang/br_flag_80.png');
const brFlagWebp = require('../../images/lang/br_flag_80.webp');

const LangSelect = () => {
  const availableLang = langConfig.AVAILABLE_LANGS;
  const langSelectorDisplay = langConfig.LANG_SELECTOR_DISPLAY;
  const { lang, handleLangChange } = useContext(LangContext);

  const [displayedLang, setDisplayedLang] = useState(
    langConfig.AVAILABLE_LANGS[1]
  );

  useEffect(() => {
    switch (lang) {
      case langConfig.AVAILABLE_LANGS[0]:
        setDisplayedLang(langConfig.AVAILABLE_LANGS[0]);
        return;
      case langConfig.AVAILABLE_LANGS[1]:
        setDisplayedLang(langConfig.AVAILABLE_LANGS[1]);
        return;
      case langConfig.AVAILABLE_LANGS[2]:
        setDisplayedLang(langConfig.AVAILABLE_LANGS[2]);
        return;
      case langConfig.AVAILABLE_LANGS[3]:
        setDisplayedLang(langConfig.AVAILABLE_LANGS[3]);
        return;
      default:
        setDisplayedLang(langConfig.AVAILABLE_LANGS[1]);
    }
  }, [lang]);

  const langFlag = (_lang) => {
    switch (_lang) {
      case langConfig.AVAILABLE_LANGS[0]:
        return (
          <ImageFormatter
            className={'earthBlackLogo'}
            webpImage={frFlagWebp}
            image={frFlag}
            alt={'yobale-lang-selector-french'}
            width={'35'}
            height={'23'}
          />
        );
      case langConfig.AVAILABLE_LANGS[1]:
        return (
          <ImageFormatter
            className={'earthBlackLogo'}
            webpImage={enFlagWebp}
            image={enFlag}
            alt={'yobale-lang-selector-english'}
            width={'35'}
            height={'23'}
          />
        );
      case langConfig.AVAILABLE_LANGS[2]:
        return (
          <ImageFormatter
            className={'earthBlackLogo'}
            webpImage={esFlagWebp}
            image={esFlag}
            alt={'yobale-lang-selector-spanish'}
            width={'35'}
            height={'23'}
          />
        );
      case langConfig.AVAILABLE_LANGS[3]:
        return (
          <ImageFormatter
            className={'earthBlackLogo'}
            webpImage={brFlagWebp}
            image={brFlag}
            alt={'yobale-lang-selector-brazilian'}
            width={'35'}
            height={'23'}
          />
        );
      default:
        return (
          <ImageFormatter
            className={'earthBlackLogo'}
            webpImage={enFlagWebp}
            image={enFlag}
            alt={'yobale-lang-selector-english'}
            width={'35'}
            height={'23'}
          />
        );
    }
  };

  return (
    <div className="langSelectorContainer">
      <Select
        value={displayedLang}
        onValueChange={handleLangChange}
        possibleValues={availableLang}
        displayedValues={langSelectorDisplay}
        ariaLabel={'Language'}
        langFlag={langFlag}
      />
    </div>
  );
};
export default LangSelect;
