import { useContext } from "react"
import { LangContext } from "../index"
import { pricing } from "../../config/pricing"

const usePricing = () => {
  const { lang } = useContext(LangContext)
  const currentPricing = pricing.map((item) => {
    return (
      {
        ...item,
        from: item[lang].from,
        to: item[lang].to,
      }
    )
  })
  return currentPricing;
}
export default usePricing;