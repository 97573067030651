export const pricing = [
  {
    en: {
      from: 'Dakar Airport AIBD DSS',
      to: 'Dakar center',
    },
    fr: {
      from: 'Aéroport Dakar AIBD DSS',
      to: 'Dakar centre',
    },
    es: {
      from: 'Aeropuerto de Dakar AIBD DSS',
      to: 'Centro de Dakar',
    },
    br: {
      from: 'Aeroporto de Dakar AIBD DSS',
      to: 'Centro de Dakar',
    },
    priceFCFA: 32000,
    priceEUR: 49,
  },
  {
    en: {
      from: 'Dakar Airport AIBD DSS',
      to: 'Saly Area*',
    },
    fr: {
      from: 'Aéroport Dakar AIBD DSS',
      to: 'Zone Saly*',
    },
    es: {
      from: 'Aeropuerto de Dakar AIBD DSS',
      to: 'Zona Saly*',
    },
    br: {
      from: 'Aeroporto de Dakar AIBD DSS',
      to: 'Zona Saly*',
    },
    priceFCFA: 27000,
    priceEUR: 41,
  },
  {
    en: {
      from: 'Dakar Airport AIBD DSS',
      to: 'Hotel Riu Baobab',
    },
    fr: {
      from: 'Aéroport Dakar AIBD DSS',
      to: 'Hotel Riu Baobab',
    },
    es: {
      from: 'Aeropuerto de Dakar AIBD DSS',
      to: 'Hotel Riu Baobab',
    },
    br: {
      from: 'Aeroporto de Dakar AIBD DSS',
      to: 'Hotel Riu Baobab',
    },
    priceFCFA: 32000,
    priceEUR: 49,
  },
  {
    en: {
      from: 'Dakar Airport AIBD DSS',
      to: 'Palmarin',
    },
    fr: {
      from: 'Aéroport Dakar AIBD DSS',
      to: 'Palmarin',
    },
    es: {
      from: 'Aeropuerto de Dakar AIBD DSS',
      to: 'Palmarin',
    },
    br: {
      from: 'Aeroporto de Dakar AIBD DSS',
      to: 'Palmarin',
    },
    priceFCFA: 58000,
    priceEUR: 89,
  },
  {
    en: {
      from: 'Dakar Airport AIBD DSS',
      to: 'Sine Saloum Area**',
    },
    fr: {
      from: 'Aéroport Dakar AIBD DSS',
      to: 'Zone Sine Saloum**',
    },
    es: {
      from: 'Aeropuerto de Dakar AIBD DSS',
      to: 'Zona Sine Saloum**',
    },
    br: {
      from: 'Aeroporto de Dakar AIBD DSS',
      to: 'Zona Sine Saloum**',
    },
    priceFCFA: 35000,
    priceEUR: 53,
  },
  {
    en: {
      from: 'Saly Area*',
      to: 'Sine Saloum Area**',
    },
    fr: {
      from: 'Zone Saly*',
      to: 'Zone Sine Saloum**',
    },
    es: {
      from: 'Zona Saly*',
      to: 'Zona Sine Saloum**',
    },
    br: {
      from: 'Zona Saly*',
      to: 'Zona Sine Saloum**',
    },
    priceFCFA: 17000,
    priceEUR: 26,
  },
  {
    en: {
      from: 'Saly Area*',
      to: 'Dakar center',
    },
    fr: {
      from: 'Zone Saly*',
      to: 'Dakar centre',
    },
    es: {
      from: 'Zona Saly*',
      to: 'Centro de Dakar',
    },
    br: {
      from: 'Zona Saly*',
      to: 'Centro de Dakar',
    },
    priceFCFA: 45000,
    priceEUR: 69,
  },
  {
    en: {
      from: 'Dakar center',
      to: 'Sine Saloum Area**',
    },
    fr: {
      from: 'Dakar centre',
      to: 'Zone Sine Saloum**',
    },
    es: {
      from: 'Centro de Dakar',
      to: 'Zona Sine Saloum**',
    },
    br: {
      from: 'Centro de Dakar',
      to: 'Zona Sine Saloum**',
    },
    priceFCFA: 55000,
    priceEUR: 84,
  },
];
