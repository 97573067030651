import React, { useContext } from 'react';
import styled from 'styled-components';
import { themeColors } from '../../utils';
import { 
  BookingButton, 
  DisplayContext, 
  LangSelect, 
  useTranslation 
} from '../index';

export const StyledMenu = styled.nav`
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${themeColors.yobaleWhite};
  height: 100vh;
  width: 100%;
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.isBurgerMenuOpen ? 'translateX(0)' : 'translateX(100%)'};
  
  text-transform: uppercase;
  font-size: 2rem;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.5rem;

  @media (max-width: 1280px) {
    width: 100%;
    transition: transform padding 0.3s ease-in-out;
  }

  a {
    width: min-content;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5rem;
    text-decoration: none;
    color: inherit;

    @media (max-width: 1280px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`;

const MenuMainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-gap: 10vh;
  gap: 8vh;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 0 0 0 2rem;
`;


const BurgerMenu = (props) => {
  const headerSection = useTranslation("headerSection")
  const {
		goTo,
		toggleBurgerMenu,
		toggleContactForm,
	} = useContext(DisplayContext)

  const handleOptionClick = (sectionName) => {
    goTo(sectionName);
    toggleBurgerMenu();
  }
  
  const handleContactFormClick = () => {
    toggleBurgerMenu();
    toggleContactForm();
  }

  return (
    <StyledMenu isBurgerMenuOpen={props.isBurgerMenuOpen}>
      <MenuMainContainer>
        <div className="HeaderRightButton" onClick={() => handleOptionClick("goToServices")}>{headerSection.menu1}</div>
        <div className="HeaderRightButton" onClick={() => handleOptionClick("goToRoutes")}>{headerSection.menu2}</div>
        <div className="HeaderRightButton" onClick={() => handleOptionClick("goToConcept")}>{headerSection.menu31}</div>
        <div className="HeaderRightButton" onClick={() => handleOptionClick("goToVehicules")}>{headerSection.menu32}</div>
        <div className="HeaderRightButton" onClick={() => handleOptionClick("goToTeam")}>{headerSection.menu33}</div>
        <div className="HeaderRightButton" onClick={() => handleOptionClick("goToFaq")}>{headerSection.menu6}</div>
        <BookingButton fontSize={"16px"}/>
        <div className="HeaderRightButton" onClick={() => handleContactFormClick()} >{headerSection.menu8}</div>
        <LangSelect />
      </MenuMainContainer>
    </StyledMenu>
  )
}

export default BurgerMenu;