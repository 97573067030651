const RouteCard = ({props, text}) => {
  const {from, to, priceFCFA, priceEUR} = props;
  const displayedText = `${text} ${priceFCFA} FCFA (${priceEUR}€)`;
  return (
    <div className="RouteCard">
      <h4 style={{margin: "0", padding:"0"}}>{from}</h4>
      <p style={{margin: "0", padding:"0"}}> &#x21c4; </p>
      <h4 style={{margin: "0", padding:"0"}}>{to}</h4>
      <p style={{padding: "14px 0 0 0", margin:"0", textAlign:"right", fontWeight:"600", color:"lightgrey", fontSize:"15px"}}>
        {displayedText}
      </p>
    </div>
  )
}
export default RouteCard;